<script setup lang="ts">
interface Props {
  contrastBackground?: boolean
}

withDefaults(defineProps<Props>(), {
  contrastBackground: false,
})

const curve = ref(null)
const dot = ref(null)
const dot2 = ref(null)
const dot3 = ref(null)

onMounted(async () => {
  const totalLength = await curve.value.getTotalLength()

  const p1 = curve.value.getPointAtLength(0.15 * totalLength)
  const p2 = curve.value.getPointAtLength(0.4 * totalLength)
  const p3 = curve.value.getPointAtLength(0.6 * totalLength)

  dot.value.setAttribute('transform', `translate(${p1.x}, ${p1.y})`)
  dot2.value.setAttribute('transform', `translate(${p2.x}, ${p2.y})`)
  dot3.value.setAttribute('transform', `translate(${p3.x}, ${p3.y})`)
})
</script>

<template>
  <section>
    <div :class="contrastBackground ? 'bg-milky-200' : ''" class="w-full">
      <div class="max-w-[1012px] w-full px-4 mx-auto py-12 lg:py-28 flex flex-row gap-10">
        <div>
          <h2 class="text-m-h2 lg:text-h2 pb-6 lg:pb-10">
            С Рангом компания получает
            в два раза больше клиентов из карт
          </h2>
          <div class="flex gap-10 lg:gap-0 lg:flex-row flex-col max-lg:items-center">
            <div class="flex flex-row lg:flex-col gap-4">
              <div>
                <div class="mb-1.5">
                  <div class="bg-white border border-black rounded-md px-2 py-1.5 whitespace-nowrap">
                    <span class="lg:text-primary-small text-[11px] mr-2">Компания в моём городе</span> <Icon size="24px" name="ph:magnifying-glass-light" />
                  </div>
                </div>
                <div class="bg-white rounded-md flex flex-col p-2 mb-1">
                  <div class="text-primary-small text-right min-h-[18px]">
                    5,0
                  </div>
                  <div class="text-primary-small min-h-[18px]" />
                </div>
                <div class="bg-white rounded-md flex flex-col p-2 mb-1">
                  <div class="text-primary-small text-right min-h-[18px]">
                    4,9
                  </div>
                  <div class="text-primary-small min-h-[18px]" />
                </div>
                <div class="bg-white rounded-md flex flex-col p-2 mb-1">
                  <div class="text-primary-small text-right min-h-[18px]">
                    4,6
                  </div>
                  <div class="text-primary-small min-h-[18px]" />
                </div>
                <div class="bg-orange-500 rounded-md flex flex-col p-2 mb-1">
                  <div class="text-primary-small text-white text-right min-h-[18px]">
                    4,2
                  </div>
                  <div class="text-primary-small text-white min-h-[18px]">
                    Ваша компания
                  </div>
                </div>
              </div>
              <div>
                <p class="max-w-[210px] text-m-primary-small lg:text-primary-small lg:font-semibold font-semibold">
                  Первым делом клиент в картах открывает карточки с высоким рейтингом.
                </p>
                <p class="max-w-[210px] text-m-primary-small lg:text-primary-small lg:mt-0 mt-4 lg:font-semibold font-semibold ">
                  Компании с низким рейтингом теряются в выдаче и не вызывают доверия.
                </p>
              </div>
            </div>
            <div>
              <div class="flex flex-row lg:flex-col gap-4">
                <div class="lg:px-4 lg:mt-12 min-w-[182px] w-[182px] lg:w-[300px] relative">
                  <svg id="svg" viewBox="0 0 600 510" xmlns="http://www.w3.org/2000/svg">
                    <defs>
                      <!-- A marker to be used as an arrowhead -->
                      <marker
                        id="arrow"
                        viewBox="0 0 30 30"
                        refX="13"
                        refY="15"
                        markerWidth="10"
                        markerHeight="10"
                      >
                        <path xmlns="http://www.w3.org/2000/svg" d="M16.4285 13.7226L3.09514 0.389607C2.57451 -0.131105 1.73025 -0.131105 1.20953 0.389696C0.68891 0.910409 0.68891 1.75459 1.20962 2.2753L13.6001 14.6655L1.20953 27.0564C0.68891 27.5771 0.68891 28.4213 1.20962 28.942C1.46989 29.2024 1.81113 29.3325 2.15238 29.3325C2.49362 29.3325 2.83487 29.2024 3.09523 28.9419L16.4285 15.6082C16.6786 15.3582 16.8191 15.0191 16.8191 14.6655C16.8191 14.3119 16.6786 13.9727 16.4285 13.7226Z" fill="black" />                      </marker>
                    </defs>

                    <circle
                      ref="dot"
                      r="10"
                      cy="0"
                      cx="0"
                      style="fill: #000"
                    />
                    <circle
                      ref="dot2"
                      r="10"
                      cy="0"
                      cx="0"
                      style="fill: #000"
                    />
                    <circle
                      ref="dot3"
                      r="10"
                      cy="0"
                      cx="0"
                      style="fill: #000"
                    />
                    <path
                      ref="curve"
                      d="M11,442 C271,443 316,62 588,62"
                      stroke="black"
                      fill="none"
                      stroke-width="3"
                      marker-end="url(#arrow)"
                    />

                  </svg>
                  <div class="absolute left-[85px] top-[85px] lg:left-[140px] lg:top-[140px]">
                    <span class="text-m-primary-small lg:text-primary-small">130 отзывов</span>
                    <div class="flex flex-row flex-nowrap">
                      <Icon v-for="i in 5" :key="i" class="text-yellow-500" name="mdi:star" />
                    </div>
                  </div>
                  <div class="absolute hidden lg:block lg:left-[80px] lg:top-[190px]">
                    <span class="text-m-primary-small lg:text-primary-small">20 отзывов</span>
                    <div class="flex flex-row flex-nowrap">
                      <Icon v-for="i in 5" :key="i" class="text-yellow-500" name="mdi:star" />
                    </div>
                  </div>
                  <div class="absolute left-[20px] top-[20px] lg:left-[180px] lg:top-[80px]">
                    <span class="text-m-primary-small lg:text-primary-small">240 отзывов</span>
                    <div class="flex flex-row flex-nowrap">
                      <Icon v-for="i in 5" :key="i" class="text-yellow-500" name="mdi:star" />
                    </div>
                  </div>
                </div>
                <div class="lg:ml-6">
                  <p class="max-w-[210px] lg:mt-1 text-m-primary-small lg:text-primary-small lg:font-semibold font-semibold ">
                    С Рангом карточка компании поднимается выше за счет большого потока настоящих хороших отзывов.
                  </p>
                  <p class="max-w-[210px] lg:mt-0 mt-2 text-m-primary-small lg:text-primary-small lg:font-semibold font-semibold">А негатив не доходит до публикации.</p>
                </div>
              </div>
            </div>
            <div class="flex-1 flex flex-row lg:flex-col gap-4">
              <div>
                <div class="mb-1.5">
                  <div class="bg-white border border-black rounded-md px-2 py-1.5 whitespace-nowrap">
                    <span class="lg:text-primary-small text-[11px] mr-2">Компания в моём городе</span> <Icon size="24px" name="ph:magnifying-glass-light" />
                  </div>
                </div>
                <div class="bg-green-500 rounded-md text-white flex flex-col p-2 mb-1">
                  <div class="text-primary-small  text-right min-h-[18px]">
                    5,0
                  </div>
                  <div class="text-primary-small min-h-[18px]">
                    Ваша компания
                  </div>
                </div>
                <div class="bg-white rounded-md flex flex-col p-2 mb-1">
                  <div class="text-primary-small text-right min-h-[18px]">
                    4,9
                  </div>
                  <div class="text-primary-small min-h-[18px]" />
                </div>
                <div class="bg-white rounded-md flex flex-col p-2 mb-1">
                  <div class="text-primary-small text-right min-h-[18px]">
                    4,6
                  </div>
                  <div class="text-primary-small min-h-[18px]" />
                </div>
                <div class="bg-white rounded-md flex flex-col p-2 mb-1">
                  <div class="text-primary-small  text-right min-h-[18px]">
                    4,2
                  </div>
                  <div class="text-primary-small  min-h-[18px]" />
                </div>
              </div>

              <div>
                <p class="max-w-[210px] text-m-primary-small lg:text-primary-small lg:font-semibold font-semibold">
                  Благодаря хорошим отзывам растёт и рейтинг компании.
                </p>
                <p class="max-w-[210px] text-m-primary-small lg:text-primary-small lg:mt-0 mt-2 lg:font-semibold font-semibold ">
                  Клиенты чаще замечают и выбирают среди конкурентов именно вашу компанию.
                </p>
              </div>
            </div>
            <div class="w-[50px] hidden lg:block">
              <svg id="svg1" viewBox="0 0 100 300" xmlns="http://www.w3.org/2000/svg">

                <path
                  d="M15,150 C70,150 25,25 90,40"
                  stroke="black"
                  fill="none"
                  stroke-width="3"
                  marker-end="url(#arrow)"
                />

              </svg>
            </div>
            <div class="lg:w-[217px] flex flex-row lg:flex-col gap-4 flex-1">
              <div>
                <div class="lg:pl-6 w-[182px] lg:w-auto pr-10 pt-5 pb-6 bg-green-500 rounded-md text-white">
                  <div class="flex flex-col gap-2.5">
                    <div class="flex flex-row gap-2">
                      <span class="text-primary-large w-1/3 text-right">x10</span>
                      <span class="text-primary-small w-2/3">переходов на сайт из карточки</span>
                    </div>
                    <div class="flex flex-row gap-2">
                      <span class="text-primary-large w-1/3 text-right">x4</span>
                      <span class="text-primary-small w-2/3">переходов в карточку компании</span>
                    </div>
                    <div class="flex flex-row gap-2">
                      <span class="text-primary-large w-1/3 text-right">x7</span>
                      <span class="text-primary-small w-2/3 ">построений маршрутов </span>
                    </div>
                    <div class="flex flex-row gap-2">
                      <span class="text-primary-large w-1/3 text-right">x2</span>
                      <span class="text-primary-small w-2/3">звонков из карточки</span>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <p class="max-w-[210px] text-m-primary-small lg:text-primary-small lg:font-semibold font-semibold  lg:mt-1">
                  + Ваш сайт поднимается выше в SEO, а компании с плохими отзывами оказываются ниже в выдаче;
                </p>
                <p class="max-w-[210px] text-m-primary-small lg:text-primary-small lg:mt-0 mt-2 lg:font-semibold font-semibold ">
                  + Люди быстрее и охотнее покупают, читая хорошие отзывы про компанию.
                </p>
                <p class="max-w-[210px] text-m-primary-small lg:text-primary-small lg:mt-0 mt-2 lg:font-semibold font-semibold ">
                  + Растёт CTR сайта и контекстной рекламы — благодаря сниппетам с высоким рейтингом;
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
